import React from 'react'
import {Text, useMultiStyleConfig} from '@chakra-ui/react'
import {Flex} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'

import {styleConfigChakraKeys} from '../../constants'
import {useCurrency} from '../../hooks/use-currency'
import CurrencyFormatter from '../currency-formatter/CurrencyFormatter'

export default function ProductPricing({
    product,
    scope,
    hideCrossedPrice = false,
    valueSetPrice
}) {
    const intl = useIntl()

    const {currency: activeCurrency} = useCurrency()
    const {
        currency,
        salePrice = 0,
        regularPrice = 0,
        promotionPrice = 0
    } = product

    const lowerPrice =
        promotionPrice && promotionPrice < salePrice
            ? promotionPrice
            : salePrice
    const styles = useMultiStyleConfig(styleConfigChakraKeys.ProductPricing, {
        variant: scope
    })

    return (
        <>
            <Flex
                fontSize={product.isPdp ? '16px' : ['10px', '10px', '16px']}
                fontWeight="300"
            >
                {lowerPrice > 0 && (
                    <Text marginRight="7px" {...styles.price}>
                        <CurrencyFormatter
                            value={lowerPrice}
                            currency={currency || activeCurrency}
                        />
                    </Text>
                )}
                {!hideCrossedPrice && regularPrice > lowerPrice && (
                    <Text {...styles.originalPrice} as="s" color="#8F9292">
                        <CurrencyFormatter
                            value={regularPrice}
                            currency={currency || activeCurrency}
                        />
                    </Text>
                )}
                {valueSetPrice && (
                    <Flex color=" #A6A6A6" fontSize="16px">
                        <>
                            <Text>
                                {' ( '}
                                {intl.formatMessage({
                                    id: 'pdp.price.value_set',
                                    defaultMessage: 'Value'
                                })}
                            </Text>
                            <Text>
                                {' '}
                                <CurrencyFormatter
                                    value={valueSetPrice}
                                    currency={currency || activeCurrency}
                                />
                                {' )'}
                            </Text>
                        </>
                    </Flex>
                )}
            </Flex>
        </>
    )
}
ProductPricing.propTypes = {
    product: PropTypes.shape({
        currency: PropTypes.string,
        salePrice: PropTypes.number,
        promotionPrice: PropTypes.number,
        regularPrice: PropTypes.number,
        isPdp: PropTypes.bool
    }),
    scope: PropTypes.string,
    hideCrossedPrice: PropTypes.bool,
    valueSetPrice: PropTypes.string
}
